(function($) {
	Common = {
		init: function() {
			this.anchor()
			this.scrollMagic()
			this.faqAccordion()
			this.topPage()
			this.header()
		},
		header: function(){
			$('.header-open').on('click', function() {
				if ($(this).hasClass('open')) {
					$(this).removeClass('open')
					$('header').removeClass('open')
					$('header .flex').fadeOut()
					$('.header-menu-sp').removeClass('active').fadeOut()
				} else {
					$(this).addClass('open')
					$('header').addClass('open')
					$('header .flex').fadeIn()
					$('.header-menu-sp').fadeIn().addClass('active')
				}
			})
		},
		topPage: function(){
			$('#topPage a').on('click', function(e){
				e.preventDefault()
				$('html, body').animate({
					scrollTop: 0
				}, 500);
			})
		},
		faqAccordion: function() {
			$('.faq-accordion').on('click', function(){
				if ($(this).hasClass('active')) {
					$(this).removeClass('active')
					$(this).siblings('dd').slideUp()
				}
				else{
					$(this).addClass('active')
					$(this).siblings('dd').slideDown()
				}
			})
		},
		anchor: function() {
			// $(window).on('load', function(){
			// 	if(window.location.hash) {
			// 		$('html, body').animate({
	  		// 		  scrollTop: $($.attr(this, window.location.hash.substring(1))).offset().top - 60
	  		// 	  }, 500);
			//   	}
			// })

			$(document).on('click', 'a[href^="#"]', function(event) {
				event.preventDefault()
				// var plus = 0;
				// if ($(window).innerWidth() < 768) {
				// 	plus = 40;
				// }
				// else{
				// 	plus = 60;
				// }
				$('html, body').animate({
					scrollTop: $($.attr(this, 'href')).offset().top
				}, 500);
			});

		},
		scrollMagic: function() {
			var controller = new ScrollMagic.Controller()
			var targets = $('.scrollmagic')
			var scenes = []

			targets.each(function() {
				var target = this;
				var scene = new ScrollMagic.Scene({
					triggerElement: target,
					triggerHook: .7
				});
				scene.on('enter', function(e) {
					$(target).addClass('magic')
				});
				scene.on('leave', function(e) {
					// $(target).removeClass('magic')
				});
				scenes.push(scene);
			});
			controller.addScene(scenes);
		},
	}



	$(function() {
		Common.init();
	})
})(jQuery)